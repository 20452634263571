@import '../../assets/scss/variables';

.Bug {
  position: fixed;
  bottom: 2%;
  right: 1%;
  cursor: pointer;
  background-color: white;
  padding: 0.3rem;
  border-radius: 5px;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.19), 0 0.2rem 0.2rem rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;
  span {
    color: black;
    margin: 0 0.3rem;
  }
  svg {
    color: $tomato;
  }
  &:hover {
    background-color: $tomato;
    span {
      color: white;
    }
    svg {
      color: white;
    }
  }
  @media (max-width: $mobile-breakpoint) {
    display: none;
  }
}
