@import '../../../assets/scss/variables';

.mainWrapper {
  font-family: 'Rubik', sans-serif;
  background-size: cover;
  background-blend-mode: luminosity;
  display: flex;
  height: calc(100% - 100px);
  overflow-y: hidden;
}

.discord-logo {
  &:hover {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

.Header-wrapper {
  padding: 0 5vw;
}

.sign-in-text {
  font-size: 14px;
  margin-bottom: 0;
  &__error {
    color: $tomato;
  }
}

.wrapper {
  font-size: 80px;
  display: inline;
  text-transform: uppercase;
  text-align: center;
  margin-top: 5rem;
  @media screen and (max-width: $breakpoint) {
    font-size: 50px;
  }
}

.ready {
  transition: all ease 0.1s;
  display: inline;
  position: relative;
  span {
    position: absolute;
    right: 0;
  }
}

.card {
  margin-top: 30px;
}

.cardHeader:first-child {
  border-radius: 5px;
  small {
    font-size: 12px;
  }
}

.glitch {
  color: #007398;
  font-size: 80px;
  position: relative;
  display: inline-block;
  margin: 0 auto;
  text-transform: uppercase;
  padding-right: 2.8rem;
  @media screen and (max-width: $breakpoint) {
    font-size: 50px;
  }
}

@keyframes noise-anim {
  0% {
    clip: rect(50px, 9999px, 3px, 0);
  }
  5% {
    clip: rect(23px, 9999px, 62px, 0);
  }
  10% {
    clip: rect(93px, 9999px, 21px, 0);
  }
  15% {
    clip: rect(49px, 9999px, 42px, 0);
  }
  20% {
    clip: rect(40px, 9999px, 49px, 0);
  }
  25% {
    clip: rect(65px, 9999px, 16px, 0);
  }
  30% {
    clip: rect(49px, 9999px, 92px, 0);
  }
  35% {
    clip: rect(89px, 9999px, 38px, 0);
  }
  40% {
    clip: rect(17px, 9999px, 47px, 0);
  }
  45% {
    clip: rect(83px, 9999px, 34px, 0);
  }
  50% {
    clip: rect(91px, 9999px, 57px, 0);
  }
  55% {
    clip: rect(32px, 9999px, 2px, 0);
  }
  60% {
    clip: rect(35px, 9999px, 10px, 0);
  }
  65% {
    clip: rect(13px, 9999px, 50px, 0);
  }
  70% {
    clip: rect(48px, 9999px, 53px, 0);
  }
  75% {
    clip: rect(4px, 9999px, 49px, 0);
  }
  80% {
    clip: rect(94px, 9999px, 92px, 0);
  }
  85% {
    clip: rect(4px, 9999px, 36px, 0);
  }
  90% {
    clip: rect(82px, 9999px, 67px, 0);
  }
  95% {
    clip: rect(66px, 9999px, 51px, 0);
  }
  100% {
    clip: rect(75px, 9999px, 34px, 0);
  }
}
.glitch:after {
  content: attr(data-text);
  position: absolute;

  left: 2px;
  text-shadow: -1px 0 white;
  top: 0;
  color: #007398;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}

@keyframes noise-anim-2 {
  0% {
    clip: rect(78px, 9999px, 86px, 0);
  }
  5% {
    clip: rect(73px, 9999px, 30px, 0);
  }
  10% {
    clip: rect(21px, 9999px, 66px, 0);
  }
  15% {
    clip: rect(12px, 9999px, 90px, 0);
  }
  20% {
    clip: rect(9px, 9999px, 85px, 0);
  }
  25% {
    clip: rect(54px, 9999px, 2px, 0);
  }
  30% {
    clip: rect(79px, 9999px, 24px, 0);
  }
  35% {
    clip: rect(63px, 9999px, 82px, 0);
  }
  40% {
    clip: rect(24px, 9999px, 73px, 0);
  }
  45% {
    clip: rect(85px, 9999px, 32px, 0);
  }
  50% {
    clip: rect(49px, 9999px, 58px, 0);
  }
  55% {
    clip: rect(41px, 9999px, 93px, 0);
  }
  60% {
    clip: rect(3px, 9999px, 75px, 0);
  }
  65% {
    clip: rect(78px, 9999px, 70px, 0);
  }
  70% {
    clip: rect(91px, 9999px, 10px, 0);
  }
  75% {
    clip: rect(11px, 9999px, 38px, 0);
  }
  80% {
    clip: rect(26px, 9999px, 81px, 0);
  }
  85% {
    clip: rect(81px, 9999px, 96px, 0);
  }
  90% {
    clip: rect(57px, 9999px, 45px, 0);
  }
  95% {
    clip: rect(32px, 9999px, 26px, 0);
  }
  100% {
    clip: rect(2px, 9999px, 68px, 0);
  }
}
.glitch:before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 blue;
  top: 0;
  color: #007398;
  // background: #181818;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}

.animated-ellipsis {
  margin-top: -10px;
  span {
    font-size: 30px;
  }
  .one {
    opacity: 1;
    -webkit-animation: dot 1s infinite;
    -webkit-animation-delay: 0s;
    animation: dot 1s infinite;
    animation-delay: 0s;
  }

  .two {
    opacity: 1;
    -webkit-animation: dot 1s infinite;
    -webkit-animation-delay: 0.2s;
    animation: dot 1s infinite;
    animation-delay: 0.2s;
  }

  .three {
    opacity: 1;
    -webkit-animation: dot 1s infinite;
    -webkit-animation-delay: 0.3s;
    animation: dot 1s infinite;
    animation-delay: 0.3s;
  }

  @-webkit-keyframes dot {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes dot {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}
