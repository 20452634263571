@import '../../../assets/scss/variables';

.Answer-container {
  display: flex;
  transition: 1.5s linear all;
  border: solid 2px transparent;

  .Answer {
    padding-right: 5%;
    width: 100%;
    &-delete-mode {
      width: 300px;
      padding: 6px;
      margin-top: 1rem;
    }
    .answer-text {
      margin: 0 0 2rem 0;
      a {
        color: #5e72e4 !important;
      }
    }
  }
  .Answer-sidebox {
    width: 5%;
    @media only screen and (max-width: $mobile-breakpoint) {
      width: 25px;
    }
    .check {
      margin-top: 1rem;
      color: $bulbasaur;
      width: 2rem;
      font-size: 1.8rem;
    }
  }

  .Answer-info-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .edit-container .center .delete-container {
    flex-grow: 1;
    align-items: center;
  }

  .delete-confirmation {
    text-align: center;
  }
}

.scrolledTo {
  border-radius: 10px;
  border: solid 2px #1098c5;
}
