.Main {
  padding: 0 5vw;
  background: url('/assets/img/BackgroundWaveCombined.svg') no-repeat center center fixed;
  min-height: 100vh;
  max-width: 100vw;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-bottom: 2rem;
  .content {
    margin-top: 2rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    .ContentBox-questions {
      width: calc(70vw - 5%);
      margin-bottom: 6rem;
      min-height: 70vh;
    }
    .ContentBox-profile {
      width: 20vw;
      height: 60vh;
      position: fixed;
      right: 5%;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      h2 {
        text-align: center;
      }
    }
  }

  @media only screen and (max-width: 1100px) {
    .content {
      flex-direction: column-reverse;
      .ContentBox-profile {
        position: unset;
        width: 95%;
        height: 50vh;
      }
      .ContentBox-questions {
        width: 95%;
      }
    }
  }
}
