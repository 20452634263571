.BackButton {
  button {
    font-weight: bold;
    position: relative;
    border: none;
    background-color: transparent;
    white-space: nowrap;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }
    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      right: 0;
      background-color: #fff;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }
    &:hover:before {
      background-color: black;
      visibility: visible;
      width: 100%;
    }
  }
}
