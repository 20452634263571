@import '../../../assets/scss/variables';

.QuestionListItem {
  display: flex;
  flex-direction: row;
  transition: 1.5s linear all;
  padding: 0.8rem 0;
  border: solid 2px transparent;
  border-radius: 10px;
  border-bottom: 1px solid rgb(239, 240, 241);
  align-items: center;
  .answers-votes-container {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    min-width: 65px;
    .answers-container {
      padding: 7px 10px 5px;
      border-radius: 3px;
      text-align: center;
      font-size: 12px;
      span {
        font-weight: bold;
        font-size: 20px;
      }
      &.answered {
        background-color: rgb(94, 186, 125);
        color: white;
      }
    }
  }
  .question-details-container {
    overflow: hidden;
    flex: 1;
    h3 {
      line-height: 1;
    }
    p {
      margin: 0 0 0.5rem 0;
      padding: 0;
      overflow-wrap: break-word;
    }
  }
  .tags-and-user-container {
    display: flex;
    .tags-container {
      display: flex;
      flex-wrap: wrap;
      max-width: 50%;
    }
  }
  .question-body {
    font-size: 12px;
    max-width: 90%;
  }
  .question-link {
    color: $wbs-blue;
    font-size: 16px;
    &:hover {
      color: rgb(0, 149, 255);
    }
  }

  @media screen and (max-width: $breakpoint) {
    flex-wrap: wrap;
    .answers-votes-container {
      flex: 0 0 20%;
    }
    .question-details-container {
      flex: 0 0 70%;
    }
    .QuestionListItem-UserInfo {
      flex: 0 0 50%;
      margin-left: auto;
    }
  }
}

.recentQuestion {
  border: solid 2px #1098c5;
  border-radius: 10px;
}
