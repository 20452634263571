@import '../../../assets/scss/variables';

.SubmitButton {
  width: 150px;
  height: 50px;
  text-align: center;
  button {
    outline: none;
    height: 50px;
    text-align: center;
    width: 140px;
    border-radius: 40px;
    background: $wbs-blue;
    border: 2px solid $wbs-blue;
    color: white;
    letter-spacing: 1px;
    text-shadow: 0;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.25s ease;
    &:focus {
      outline: none;
    }

    &:hover {
      color: $wbs-blue;
      background-color: white;
    }
  }
  .error {
    background-color: white;
    border: $tomato 2px solid;
    color: $tomato;
    &:hover {
      color: $tomato;
      cursor: not-allowed;
    }
  }
  .submitting {
    width: 50px;
    border-color: gray;
    border-width: 3px;
    font-size: 0;
    border-left-color: $wbs-blue;
    background-color: white;
    animation: rotating 2s 0.25s linear infinite;

    &:hover {
      color: $wbs-blue;
      background: white;
    }
  }
  .validated {
    font-size: 13px;
    color: white;
    background: $wbs-blue;
    cursor: unset;
    &:hover {
      background-color: $wbs-blue;
      color: white;
    }
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.inverted {
  button {
    background: white;
    border: 2px solid $wbs-blue;
    color: $wbs-blue;
  }
}

.borderless {
  button {
    border: none;
  }
}
