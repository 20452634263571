.aisSearchBox {
  position: relative;
  min-width: 22vw;
  height: 100%;
  display: flex;
  align-items: center;
  .aisSearchBoxForm {
    display: flex;
    align-items: center;
    border-radius: 35px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.19), 0 0.5rem 0.5rem rgba(0, 0, 0, 0.23);
    background-color: white;
    height: 4rem;
    width: 100%;
    margin: 1rem;
    padding: 1rem;

    input {
      border: 0;
      margin-left: 1rem;
      width: 100%;
      height: 100%;
    }
  }
}
