.AskQuestion {
  .error-message {
    color: red;
  }
  h3 {
    margin-bottom: 0.5rem;
    font-size: 20px;
  }

  input {
    width: 100%;
  }
  .TextEditor {
    width: 100%;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 3rem;
    .error-message {
      position: absolute;
      top: 100%;
    }
  }

  .submit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  &-subtitle-container {
    p {
      margin-bottom: 5px;
    }
  }

  &-title-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 3rem;
    flex-wrap: wrap;

    .BackButton {
      flex: 1;
    }

    &::after {
      content: '';
      flex: 1;
    }
  }
}
