@import '../../../assets/scss/variables';

.QuestionPage {
  height: 100%;

  h3 {
    font-size: 20px;
  }
  .basic-button {
    border: none;
    background-color: transparent;
    white-space: nowrap;
    cursor: pointer;
  }
  .edit-button {
    font-size: 14px;
    color: $wbs-blue;
    font-weight: bold;
  }
  .deleted-question-container {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .QuestionPage-ask-user {
    margin-left: auto;
    margin-top: 0;
  }
  .edit-container {
    &.centered {
      display: flex;
      justify-content: center;
      position: relative;
      p {
        color: $tomato;
        position: absolute;
        top: -30px;
        font-weight: bold;
        font-size: 14px;
      }
    }
    &.delete-container {
      position: unset;
      flex-direction: column;
      align-items: center;
      p {
        position: unset;
      }
      .delete-buttons {
        display: flex;
      }
    }
  }
  .question-info-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .question-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
    min-height: 5rem;
    a {
      color: #5e72e4 !important;
    }
  }
  h1 {
    text-align: center;

    [class*='icon'] {
      font-size: 25px;
      cursor: pointer;
      margin-left: 0.5rem;
    }
    .icon-edit {
      color: $wbs-blue;
    }
    .icon-confirm {
      color: $bulbasaur;
    }
    .icon-cancel {
      color: $tomato;
    }
  }
}
