.container {
  min-height: 30rem;
  height: auto;
  min-width: 3rem;
  padding: 2rem;
  margin: 1rem;
  border-radius: 35px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.19), 0 0.5rem 0.5rem rgba(0, 0, 0, 0.23);
  background-color: white;
}
