.QuestionModule {
  .title-edit-container {
    margin-top: 2rem;

    input {
      width: 100%;
      border: 1px solid #ccc;
      font-size: 30px;
    }
  }
}
