.ProfilePage {
  h1 {
    text-align: center;
  }
  .profileAvatar-updated {
    background-color: blue;
  }

  &-title-container {
    align-items: center;
    width: 100%;
    margin-bottom: 3rem;
  }
  &-username-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-editButton {
    cursor: pointer;
    margin-left: 10px;
  }
  &-editModeButtons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  &-editMode {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  &-editModeInput {
    display: flex;
    justify-content: center;
  }
  &-input {
    line-height: 30px;
  }
  &-userNameEdit {
    justify-content: center;
    display: flex;
    align-items: center;
  }

  &-content-container {
    display: flex;
    flex-direction: row;
  }

  &-bio-edit-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-avatar-bio-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .badge-activity-container {
    width: 50%;
    padding-left: 3rem;

    ul {
      padding-left: 0px;
      margin-bottom: 0px;
    }
  }

  .badge-activity-container li {
    display: flex;
    margin-bottom: 0px;
    align-items: center;
  }

  .ProfilePage-bio-container {
    text-align: center;

    .badge-activity-container li a {
      max-width: 50%;
      margin-right: 2%;
      text-overflow: ellipsis;
      -ms-text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    @media only screen and (max-width: 800px) {
      .ProfilePage-content-container {
        flex-direction: column;
        .badge-activity-container {
          width: 100%;
          margin-top: 1rem;
          padding-left: 0;
          h3 {
            font-size: 20px;
            // text-align: center;
          }
        }
      }
    }
  }
}
