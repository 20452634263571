@import '../../../assets/scss/variables';

.EditContainer {
  flex-grow: 1;
  .basic-button {
    border: none;
    background-color: transparent;
    white-space: nowrap;
    cursor: pointer;
  }
  .edit-button {
    font-size: 14px;
    color: $wbs-blue;
    font-weight: bold;
  }
  .delete-button {
    font-size: 14px;
    color: $tomato;
  }
  .confirm-button {
    color: $bulbasaur;
    font-weight: bold;
  }
  &.centered {
    display: flex;
    justify-content: center;
    position: relative;
    p {
      color: $tomato;
      position: absolute;
      top: -30px;
      font-weight: bold;
      font-size: 14px;
    }
  }
}
