@import '../../../assets/scss/_variables';

.AnswerBox {
  display: flex;
  margin-top: 5%;
  @media (max-width: $mobile-breakpoint) {
    margin-top: 20px;
  }

  .input-answer {
    background-color: #e5e5e5;
    width: 90%;
    border-radius: 35px;
    display: flex;
    align-items: center;
    padding-left: 2vh;
    margin-left: 1.2vh;
    font-style: italic;
    font-size: 0.8rem;
    cursor: text;

    span {
      opacity: 0.8;
    }
  }

  .input-container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 2vh;
    width: 90%;
    .error-message {
      color: $tomato;
      position: absolute;
      top: 100%;
    }
  }

  .text-editor {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .placeholder {
    font-style: italic;
  }

  img {
    width: 50px;
    align-self: flex-start;
  }
}

.button-container {
  display: flex;
  margin-top: 2em;
  justify-content: center;
}
