$wbs-blue: #007398;
$tomato: #fd495c;
$bulbasaur: #4caf50;

// Taken from the website atm.
$sm-mobile-breakpoint: 360px;
$mobile-breakpoint: 576px;
$breakpoint: 867px;
$tablet-breakpoint: 1100px;

/* Copied the variables below from the old website, 
   if we need some colours we can pick from here or feel free to delete/add to them
*/

$black: #000000;
$light-gray: #707070;
$mild-gray: #383635;
$dark-gray: #292929;
$table-border: #5d5d5d;
$white: #ffffff;
$teal: #007399;
$learningCardGray: #363535;
$mobileBlack: #292929;
$mobileGray: #4a4c4e;
$mobileButtonBackground: #3a3c3d;
$mobileTransparent: rgba(41, 41, 41, 0.5);
$bg-light-gray: rgba(112, 112, 112, 0.5);
$bg-transparent-black: rgba(0, 0, 0, 0.5);
$box-shadow: 8px 8px 6px 0 #00000029;
