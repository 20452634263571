.Test-menu {
  &.funnel {
    position: absolute;
    right: 1em;
    top: 0.8em;
    z-index: 100;
    cursor: pointer;
  }

  &.dropdown {
    cursor: pointer;
    position: relative;
    display: inline-block;
    outline: 0;
    text-align: left;
    -webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease, width 0.1s ease;
    transition: box-shadow 0.1s ease, width 0.1s ease, -webkit-box-shadow 0.1s ease;
    -webkit-tap-highlight-color: transparent;
    align-self: baseline;
  }

  &.dropdown.selection {
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
    min-width: 14em;
    min-height: 2.71428571em;
    background: #fff;
    // padding: 0.78571429em 2.1em 0.78571429em 1em;
    color: rgba(0, 0, 0, 0.87);
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    -webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease, width 0.1s ease;
    transition: box-shadow 0.1s ease, width 0.1s ease, -webkit-box-shadow 0.1s ease;
  }

  &:hover {
    border-color: rgba(34, 36, 38, 0.35);
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.dropdown.selection:hover {
    border-color: rgba(34, 36, 38, 0.35);
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.dropdown.selection:focus {
    border-color: #96c8da;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.dropdown.selection.active {
    border-color: #96c8da;
    -webkit-box-shadow: 0 2px 3px 0 rgb(34, 36, 38 / 15%);
    box-shadow: 0 2px 3px 0 rgb(34, 36, 38 / 15%);
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &.dropdown.selection.active,
  &.dropdown.selection.visible {
    z-index: 10;
  }

  .default {
    display: inline-block;
    -webkit-transition: none;
    transition: none;
    padding: 0.78571429em 2.1em 0.78571429em 1em;
  }

  .text {
    color: rgba(191, 191, 191, 0.87);
  }

  &.dropdown.selection.active:hover .menu {
    border-color: #96c8da;
    -webkit-box-shadow: 0 2px 3px 0 rgb(34, 36, 38 / 15%);
    box-shadow: 0 -2px 3px 0 rgb(0, 0, 0 / 8%);
  }

  &.dropdown.selection .menu {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;
    border-top-width: 0 !important;
    width: auto;
    outline: 0;
    margin: 0 -1px;
    min-width: calc(100% + 2px);
    width: calc(100% + 2px);
    border-radius: 0 0 0.28571429rem 0.28571429rem;
    -webkit-box-shadow: 0 2px 3px 0 rgb(34, 36, 38 / 15%);
    box-shadow: 0 2px 3px 0 rgb(34, 36, 38 / 15%);
    -webkit-transition: opacity 0.1s ease;
    transition: opacity 0.1s ease;
  }

  &.item {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1em;
    color: rgba(0, 0, 0, 0.87);
    padding: 0.78571429rem 1.14285714rem !important;
    font-size: 1rem;
    text-transform: none;
    font-weight: 400;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-touch-callout: none;
    border-top: 1px solid #fafafa;
    padding: 0.78571429rem 1.14285714rem !important;
    white-space: normal;
    word-wrap: normal;
  }

  &.item:hover {
    background: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.95);
  }

  &.transition.hidden {
    display: none;
  }

  .funnel {
    margin-left: 6em;
  }
}
