.UserInfo {
  width: 200px;
  background-color: rgb(225, 236, 244);
  height: 80px;

  .time-asked {
    color: rgb(106, 115, 124);
    margin-bottom: 0;
    font-size: 14px;
    padding: 3px 0 0 5px;
  }
  .identity {
    span {
      color: rgb(0, 119, 204);
    }
    span:hover {
      color: #0095ff;
    }
    img {
      width: 50px;
      margin: 0 5px;
    }
  }
}
.transparent {
  background-color: transparent;
}
