.Tag {
  background-color: #99d3e7;
  cursor: pointer;
  padding: 0.4em 0.5em;
  margin: 0 0.2rem 0 0;
  font-size: 12px;
  color: rgb(57, 115, 157);
  background-color: rgb(225, 236, 244);
  line-height: 12px;
  height: 24px;
  border: none;
  &:focus {
    outline: none;
  }
  &:hover {
    color: rgb(44, 87, 119);
    background-color: rgb(209, 229, 241);
  }
}
