.animated-ellipsis {
  margin-top: -10px;
  span {
    font-size: 30px;
  }
  .one {
    opacity: 1;
    -webkit-animation: dot 1s infinite;
    -webkit-animation-delay: 0s;
    animation: dot 1s infinite;
    animation-delay: 0s;
  }

  .two {
    opacity: 1;
    -webkit-animation: dot 1s infinite;
    -webkit-animation-delay: 0.2s;
    animation: dot 1s infinite;
    animation-delay: 0.2s;
  }

  .three {
    opacity: 1;
    -webkit-animation: dot 1s infinite;
    -webkit-animation-delay: 0.3s;
    animation: dot 1s infinite;
    animation-delay: 0.3s;
  }

  @-webkit-keyframes dot {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes dot {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}
