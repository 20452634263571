.ProfileAvatar {
  text-align: center;
  height: 160px;
  h2 {
    font-size: 25px;
  }

  p {
    position: relative;
    bottom: 80px;
    opacity: 0;
    text-transform: uppercase;
    transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -webkit-transition: opacity 0.3s linear;
    color: black;
    font-family: Roboto;
  }

  img {
    width: 150px;
    border-radius: 100%;
  }
}

.hoverEffect {
  &:hover img {
    opacity: 0.2;
  }

  &:hover p {
    opacity: 1;
  }
}
