@import '../../../assets/scss/variables';

.EditMode {
  .centered {
    display: flex;
    justify-content: center;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    @media only screen and (max-width: $mobile-breakpoint) {
      margin-top: 20px;
      flex-direction: column;
      align-items: center;
    }
  }
}
