@import '../../assets/scss/variables';

.Background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  transform: rotate(180deg) scaleX(-1);
  z-index: -5;
  border-bottom: white solid 5px;
  .line {
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 50%;
    // background: rgb(2, 0, 36);
    // Transparency at 100% is required, otherwise we get a weird line in different viewports
    background: linear-gradient(
      180deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 115, 152, 1) 35%,
      rgba(0, 212, 255, 1) 99%,
      transparent 100%
    );
    @media screen and (max-width: $breakpoint) {
      height: 40%;
    }
  }
  .line-1 {
    z-index: -1;
    opacity: 0.5;
  }
  .line-2 {
    z-index: -2;
    opacity: 0.7;
  }
  .line-3 {
    z-index: -3;
  }
  .wave {
    position: absolute;
    left: 0;
    width: 200%;
    height: 100%;
    background-repeat: repeat no-repeat;
    background-position: 0 bottom;
    transform-origin: center bottom;
  }
  .wave1 {
    -webkit-background-size: 50% 80px;
    background-size: 50% 80px;
    @media screen and (max-width: $breakpoint) {
      -webkit-background-size: 50%;
      background-size: 50%;
    }
  }
  .wave2 {
    -webkit-background-size: 50% 100px;
    background-size: 50% 100px;
    animation: animate 12s linear infinite;
    @media screen and (max-width: $breakpoint) {
      -webkit-background-size: 50%;
      background-size: 50%;
    }
  }
  .wave3 {
    -webkit-background-size: 50% 80px;
    background-size: 50% 80px;
    animation: animate 18s linear infinite;
    @media screen and (max-width: $breakpoint) {
      -webkit-background-size: 50%;
      background-size: 50%;
    }
  }
  @keyframes animate {
    0% {
      transform: translateX(0) translateZ(0) scaleY(1);
    }
    50% {
      transform: translateX(-25%) translateZ(0) scaleY(0.5);
    }
    100% {
      transform: translateX(-50%) translateZ(0) scaleY(1);
    }
  }
}
