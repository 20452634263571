@import '../../assets/scss/variables';

.Header {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .beta {
    font-size: 40px;
    font-family: 'Rubik', sans-serif;
  }

  .SearchBox {
    width: 100%;
  }

  .logo {
    height: 140px;
    margin-top: 1rem;
    @media screen and (max-width: $breakpoint) {
      height: 80px;
    }
  }
  .searchbar-container {
    display: flex;
    align-items: center;
    .logout {
      height: 30px;
      width: 78px;
      margin-left: auto;
      border: $tomato 2px solid;
      background-color: $tomato;
      color: $white;
      font-weight: bold;
      cursor: pointer;
      border-radius: 5px;
      white-space: nowrap;
      &:hover {
        background-color: white;
        color: $tomato;
      }
    }

    @media screen and (max-width: $tablet-breakpoint) {
      flex-direction: row-reverse;
      width: 100%;
    }
  }
}
