@import '../../../assets/scss/variables';

.QuestionList {
  height: 100%;
  display: flex;
  flex-direction: column;
  .algolia-search-container {
    display: flex;
  }
}

.missing-questions {
  text-align: center;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    margin-right: 10px;
  }
}

.questionList-Items-container {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.header-container {
  position: relative;
  .heading {
    padding-left: 1rem;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $breakpoint) {
      flex-direction: column;
      h2 {
        text-align: center;
      }
      .SortBy-container {
        margin-left: auto;
      }
    }
  }

  .filtered {
    padding-left: 1rem;
    position: absolute;
  }
}

.search-query {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  &-string {
    font-style: italic;
  }
  &-delete {
    color: red;
    cursor: pointer;
    font-size: 2rem;
    margin-left: 0.6rem;
  }
}
