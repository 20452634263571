@import '../../../assets/scss/variables';

.QuestionList-pagination {
  display: flex;
  justify-content: center;
  margin-top: auto;
  a {
    color: black;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 5px;
  }
  .ais-Pagination-item--disabled {
    display: none;
  }
  .ais-Pagination-link--selected {
    background-color: $bulbasaur;
    color: white;
    border-radius: 5px;
  }
  a:hover:not(.ais-Pagination-link--selected) {
    background-color: #ddd;
  }
}
