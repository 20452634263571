.AuthenticateURL {
  padding: 0 5vw;
  background: url('/assets/img/BackgroundWaveCombined.svg') no-repeat center center fixed;
  min-height: 100vh;
  max-width: 100vw;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  h1 {
    margin-top: 25vh;
    margin-left: 20vw;
    .one {
      opacity: 1;
      -webkit-animation: dot 1s infinite;
      -webkit-animation-delay: 0s;
      animation: dot 1s infinite;
      animation-delay: 0s;
    }

    .two {
      opacity: 1;
      -webkit-animation: dot 1s infinite;
      -webkit-animation-delay: 0.2s;
      animation: dot 1s infinite;
      animation-delay: 0.2s;
    }

    .three {
      opacity: 1;
      -webkit-animation: dot 1s infinite;
      -webkit-animation-delay: 0.3s;
      animation: dot 1s infinite;
      animation-delay: 0.3s;
    }

    @-webkit-keyframes dot {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes dot {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
  }
}
